import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const IconeHome = ({ className }) => {
  return <FontAwesomeIcon icon={"home"} className={className} />;
};

export const IconeContrat = ({ className }) => {
  return <FontAwesomeIcon icon={"calendar"} className={className} />;
};

export const IconeAppareil = ({ className }) => {
  return <FontAwesomeIcon icon={"mobile"} className={className} />;
};

export const IconeDepannage = ({ className }) => {
  return <FontAwesomeIcon icon={"wrench"} className={className} />;
};

export const IconeDevis = ({ className }) => {
  return <FontAwesomeIcon icon={"book"} className={className} />;
};

export const IconeFacture = ({ className }) => {
  return <FontAwesomeIcon icon={"file"} className={className} />;
};
